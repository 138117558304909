import { Component, Input } from '@angular/core';
import { formatePercentageValue } from '@app/core/shared/chart.helper';

@Component({
  selector: 'app-simple-pie-chart',
  templateUrl: './simple-pie-chart.component.html',
  styleUrls: ['./simple-pie-chart.component.scss'],
})
export class SimplePieChartComponent {
  @Input() showType = 'horizontal';
  @Input() max: number;
  @Input() value: number;

  public get getValue() {
    return formatePercentageValue(this.max, this.value);
  }
}
