<figure class="simple-pie-chart">
  <svg width="100%" height="100%" viewBox="2 2 38 38" class="donut">
    <circle cx="21" cy="21" r="15.91549430918952"
            fill="transparent" stroke="#EEEEEE" stroke-width="6">
    </circle>
    <circle cx="21" cy="21" r="15.91549430918952"
            fill="transparent" stroke="#5AB3E4" stroke-width="6"
            [attr.stroke-dasharray]="getValue" stroke-dashoffset="25">
    </circle>
    <g *ngIf="showType === 'horizontal'">
      <text x="55%" y="60%" class="text medium">{{ value }}/{{ max }}</text>
    </g>
    <g *ngIf="showType === 'vertical'">
      <text x="55%" y="58%" class="text text--big medium">{{ value }}</text>
      <text x="55%" y="75%" class="text medium">{{ max }}</text>
    </g>
  </svg>
</figure>
